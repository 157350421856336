import React from "react"
import Layout from '../components/layout';
import Banner from '../components/banner';
import ContentBlock from '../components/contentblock';
import { Helmet } from "react-helmet";

// IMAGES


const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>About Us | Products By Design</title>
    </Helmet>
    <Banner
      title="We provide quality products personalised to your brand."
      description="Having trouble finding the right promotional products for your business? Let us find them for you, leaving you more time to focus on what you do best – meeting your clients’ needs."
      button={{
        title: "View Products",
        url: "/"
      }}
    />
    <ContentBlock>
        <div className="container">
          <h3>Why use Personalised Products?</h3>
          <p>Here at Products by Design, we are a Christchurch based organisation, servicing all of New Zealand.
            When you come to us for your promotional products, you can expect a friendly and efficient service
            that you can rely on.</p>

            <p>If you’ve never ordered personalised products before and need that helping hand, we are here to
            walk you through it step by step. We focus on building up a rapport to understand your business so
            we can recommend products your clients will love.</p>
            <p>We provide products to suit your promotional needs. These include personalised pens, cups, totes,
            umbrellas, and so much more! Whatever type of product you would like to add your logo to, we can
            source it. We even have an eco range to show your support for environmentally friendly practices.</p>

            <p>We don’t have a minimum order quantity, so whether you’re looking for ten personalised products
            or ten thousand, we’re here to help.
            Take a look around our product pages and if you see something you like, contact us today. Once we
            have discussed your needs and preferred product, we can send you a free sample. This way, you can
            do the feel test and see for yourself how high-quality our products are.</p>
        </div>
    </ContentBlock>
  </Layout>
)

export default IndexPage
